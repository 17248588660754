<template>
	<v-app>
		<v-main>
			<v-slide-x-transition mode="out-in">
				<router-view></router-view>
			</v-slide-x-transition>
			<r-toast></r-toast>
		</v-main>
	</v-app>
</template>

<script>

export default {
	name: "Index",

}
</script>

<style scoped>

</style>